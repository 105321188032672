<template>
    <div class="main--view">
        <div class="navigation--view"
             v-show="!isLoading && !isFullscreen ">
            <div class="name--container">
                <p>{{account.fullName}}</p>
                <p class="class__text">Ученики: <b class="class__text"> {{stringClasses}} </b></p>
            </div>
            <div class="exit--container">
                <img class="exit__button-img" src="../../assets/img/exit.svg" @click="toogle">
                <div class="exit--dropdown-container" :class="{'open' : this.navBarOpened}">
                    <a @click="exit"><img src="../../assets/img/exit1.svg">Выход</a>
                </div>
            </div>
        </div>
        <div class="router--view">
            <router-view v-on:loaded="loaded" v-if="!isFakeLoad"></router-view>
        </div>
<!--        <textarea v-model="deviceToken" style="width:100%;height:200px"></textarea>-->
    </div>
</template>

<script>

    // eslint-disable-next-line no-unused-vars
    import {FirebaseService} from "@/custom/FirebaseService";
    import {NeedMoreInformationException} from "../../exception/NeedMoreInformationException";

    export default {
        name: "MainView",
        data(){
            return {
                account : {},
                navBarOpened: false,
                isLoading: true,
                firebaseService : {},
                token : "",
              isFakeLoad: false
            }
        },
        computed:{
            deviceToken(){
                return this.$store.getters.DEVICE_TOKEN
            },

            stringClasses(){
                let str= ""
              const arr = this.account.myClasses;
              if (arr == null || !typeof arr[Symbol.iterator] === 'function'){
                return "";
              }
                for(let cl of this.account.myClasses){
                  if (str === ""){
                    str+= (cl.type === 0) ? cl.fullName : cl.fullName+"гр"
                    continue
                  }

                  str+=", "
                  str+= (cl.type === 0) ? cl.fullName : cl.fullName+"гр"
                }
                return str
            },
           isFullscreen(){
            const isFullscreen = this.$store.getters.IS_FULLSCREEN_CAMERA || this.$store.getters.IS_FULLSCREEN_SCHEDULE
            try{
              const chatraContainer = document.getElementsByClassName("chatra--webkit")[0];
              console.log(chatraContainer)
              if (isFullscreen){
                //@ts-ignore
                chatraContainer.style.display = 'none'
              } else {
                //@ts-ignore
                chatraContainer.style.display = 'block'
              }
            } catch (e){
              console.error(e)
              return isFullscreen
            }

            return isFullscreen
          }

        },
        methods:{
            toogle(){
                if (this.account.type === 0){
                    this.$router.push({name : 'SettingsMainListView'})
                } else {
                    this.navBarOpened = !this.navBarOpened
                }
            },
            async exit(){
                const res = await this.$store.dispatch("EXIT_FROM_ACCOUNT");
                if (res){
                    await this.$store.dispatch("RESET_STATE_TO_DEFAULT")
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("refreshToken")
                    this.$router.push({name: "AuthView"})

                    // document.location.href = '/auth'

                }
            },
            loaded(){
                this.isLoading = false
            },
        },
        async mounted(){
            try{
              window.actions.reInitStreamView = async () => {
                this.isFakeLoad = true
                this.isLoading = true
                this.$nextTick(() => {
                  this.isLoading = false
                  this.isFakeLoad = false
                })
              }



                window.scrollTo(0,1)
                await this.$store.dispatch("UPDATE_LOCAL_PROFILE")
                if (this.deviceToken == null || this.deviceToken.length === 0){
                    this.$store.commit("SET_DEVICE_TOKEN",localStorage.getItem("deviceToken"))
                }
                // this.$store.commit("SET_PUSH_ENABLED",parseInt(localStorage.getItem("IS_PUSH_ENABLED")) == 1)
                await this.$store.dispatch("UPDATE_DEVICE_TOKEN",{token : this.deviceToken})
                this.account = this.$store.getters.PROFILE;
                //@ts-ignore
                const route = this.$route


                if (route.name === "MainView"){
                    if (this.account.type === 0){
                        this.$router.push({name : "ParentView"})
                    } else {
                        this.$router.push({name : "TeacherView"})
                    }
                }
            }catch (e) {
                if (e instanceof NeedMoreInformationException){
                    this.$router.push({name: "InterviewView"})
                }
            }
        },
    }

</script>

<style scoped lang="scss">
    .main--view{
        width: 100%;
        height: 100%;
        background-color: #f8f8f8;

        .navigation--view{
            width: 768px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 25px;
            margin-bottom: 16px;
            margin-left: auto;
            margin-right: auto;

            @include for-phone-only{
                width: 375px;

            }

            @include for-phone-landscape-up{
                width: 575px;
            }

            @include for-tablet-up{
                width: 768px;
            }

            .name--container{
                margin-left: 20px;

                p{
                    font-size: 16px;
                    font-family: Roboto, "sans-serif";
                    letter-spacing: 0;
                    line-height: 19px;
                }

                .class__text{
                    font-size: 15px;
                }

                .class__text{
                    opacity: 0.6;
                }
            }

            .exit--container{
                margin-right: 20px;
                position: relative;

                .exit__button-img{
                    width: 22px;
                }

                .exit__button-img:active{
                    opacity: 0.5;
                }

                .exit--dropdown-container{
                    position: absolute;
                    width: 180px;
                    height: 0px;
                    overflow: hidden;
                    pointer-events: none;
                    background: #FFFFFF;
                    box-shadow: 0 0 10px 0 rgba(0,0,0,0.20);
                    right: -19px;
                    transition: opacity 0.3s;
                    top: 29px;
                    opacity: 0;



                    a{
                        display: flex;
                        height: 19px;
                        margin-left: 18px;
                        margin-top: 15px;

                        font-size: 14px;
                        font-family: Roboto, "sans-serif";
                        line-height: 19px;
                        letter-spacing: 0;


                        img{
                            margin-right: 13px;
                        }
                    }


                    &.open{
                        height: 52px;
                        pointer-events: auto;
                        z-index: 99999;
                        opacity: 1;
                    }
                }
            }
        }
    }
</style>
